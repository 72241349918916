/* eslint-disable */
import { Bar, Bubble } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@mui/material/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@mui/material/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import EmailIcon from '@material-ui/icons/Email';
import LocalOffer from '@material-ui/icons/LocalOffer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StorageIcon from '@mui/icons-material/Storage';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/More';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Paper from '@material-ui/core/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import red from '@material-ui/core/colors/red';
import OutlinedInput from '@mui/material/OutlinedInput';
import { reduxForm } from 'redux-form';
import { mockDashboard, mockFeed } from '../../utils/mock';
import SelectComponent from '../../components/common/Select';
import {
  getDatabaseList,
  getSchemasList,
  getMetrics,
} from '../../reducers/database';
import { getUserDetails, labelStudioAuth } from '../../reducers/otp';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { APIConfig } from '@constants';
import user_icon from '@assets/images/default-image.png';
import { ellipsis } from '../../helpers/utils';
import Button from '@material-ui/core/Button';
import EN from '../../localization/EN';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { setDbId, setSchemaId, setDbName } from '../../reducers/search';
import { parse } from 'node-html-parser';

import {
  NewsCard,
  PostCard,
  StatCard,
  WeatherCard,
  Wrapper,
  ProductCardCompact,
} from '../../components';
import { options } from '../../utils/mock/chart';
import CardActions from '@mui/material/CardActions';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { useAppState } from '../../components/AppProvider/AppProvider';
const useStyles = makeStyles(theme => ({
  itemCard: {
    boxShadow: theme.shadows[4],
  },
}));
let id = 0;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function createData(name, user, noOrRecords, action) {
  id += 1;
  return {
    id,
    name,
    user,
    noOrRecords,
    action,
  };
}
const data = [
  createData('My youtube channel', 'Gaby', 1815, 'Manage'),
  createData('My Vidberrys channel', 'Arjuna', 650, 'Manage'),
  createData('Images', 'Spiderman', 1136, 'Manage'),
  createData('Production audio', 'Superman', 7812, 'Manage'),
];
function createDataForNotification(date, desc, action) {
  id += 1;
  return {
    id,
    date,
    desc,
    action,
  };
}
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const dataForNotification = [
  createDataForNotification(
    'March 10, 2024',
    'Created database RetailerDB',
    'Info'
  ),
  createDataForNotification(
    'February 27, 2024',
    `Auto indexed tag, 'product_type' in 'RetailerDB' bucket`,
    'More info'
  ),
  createDataForNotification(
    'January 12, 2023',
    `Populated 678 objects into RetailerDB`,
    'Info'
  ),
  createDataForNotification(
    'December 20, 2023',
    'Broken link to data',
    'Repair'
  ),
  createDataForNotification(
    'November 25, 2023',
    `Labeled image classification`,
    'Annotation'
  ),
  createDataForNotification(
    'November 11, 2023',
    `Need to resize bucket, 'RetailerDB'`,
    'Resize'
  ),
];
const styles = {
  cardHeight: {
    // boxShadow: `0px 3px 4px ${GREY}`,
    height: 135,
  },
};
const Home = props => {
  const posthog = usePostHog();
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [totalCountDatabase, setTotalCountDatabase] = useState();
  const [totalCountSchema, setTotalCountSchema] = useState();
  const [myList, setMyList] = useState([]);
  const [testing5, setTesting5] = useState({});
  // const [personName, setPersonName] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [metrics, setMetrics] = useState();
  const [ellipsisChar, setEllipsisChar] = useState('');
  const [state] = useState({ age: '', name: 'hai' });
  const [apiKey, setApiKey] = useState('');
  const [appState, dispatch] = useAppState();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (window.innerWidth > 1600) {
      setEllipsisChar(30);
    } else if (window.innerWidth > 1900) {
      setEllipsisChar(40);
    } else {
      setEllipsisChar(20);
    }
  }, []);
  useEffect(() => {
    const userEmail = window.localStorage.getItem('userEmail');
    let userId = '';
    props.getUserDetails(userEmail).then(userInfo => {
      const userSurrogateId = userInfo.userDetails.userSurrogateId;
      const orgName = userInfo.userDetails.orgName;
      const apiKey = userInfo.userDetails.apiKey;

      window.localStorage.setItem('userSurrogateId', userSurrogateId);
      window.localStorage.setItem('apiKey', apiKey);
      window.localStorage.setItem('orgName', orgName);
      window.localStorage.setItem('apiKey', apiKey);

      posthog?.identify(userEmail, {
        email: userEmail,
        userSurrogateId: userSurrogateId,
        orgName: orgName,
      });

      userId = userSurrogateId;
      setApiKey(userInfo.userDetails.apiKey);
      props.getDatabaseList(userId, 0, 0).then(results => {
        const databaseListDropdown =
          results.responseList &&
          results.responseList.map(Database => {
            return Database.name;
          });
        setOptions(databaseListDropdown);
        const databaseList =
          results.responseList &&
          results.responseList.map(Database => {
            return Database;
          });
        setListOptions(databaseList.slice(0, 8));
        const databaseTotalCount = results.totalCount;
        setTotalCountDatabase(databaseTotalCount);
      });
      props.getSchemasList(userId, 1, 8).then(results => {
        const schemasList =
          results.responseList &&
          results.responseList.map(eachSchema => {
            return eachSchema;
          });
        setSchemas(schemasList);
        const schemaTotalcount = results.totalCount;
        setTotalCountSchema(schemaTotalcount);
      });
      props.getMetrics(userId).then(result => {
        setMetrics(result.metrics);
      });
    });
  }, []);

  const renderDropdown = () => {
    return (
      <Box sx={{ minWidth: 120, display: 'inline-block', float: 'right' }}>
        <FormControl className="w-200">
          <SelectComponent list={options} placeHolder="By Database" />
        </FormControl>
      </Box>
    );
  };

  const SchemaRedirect = id => {
    props.history.push(`/schemaDetails/${id}`);
  };

  const renderschemasList = () => {
    return schemas.map(schema => (
      <Grid key={schema.id} item xs={12} sm={6} md={3} className="cursor">
        <Card className={classes.itemCard}>
          <CardContent
            style={styles.cardHeight}
            onClick={() => SchemaRedirect(schema.id)}
          >
            <Typography
              variant="h5"
              component="div"
              style={{ fontSize: 14, lineHeight: 2 }}
            >
              {schema.name}
            </Typography>
            <Typography style={{ fontSize: 12, lineHeight: 2 }} gutterBottom>
              {ellipsis(schema.description, ellipsisChar)}
            </Typography>
            <Typography
              className="grayFont"
              style={{ fontSize: 11, lineHeight: 2 }}
            >
              {moment
                .utc(schema.createdTime)
                .tz(moment.tz.guess(true))
                .format('ll')}
            </Typography>
            <Typography style={{ color: 'Grey', fontSize: 11 }} gutterBottom>
              {schema.type ? 'User generated ' : 'Built-in'}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const handleRedirectFilter = (id, schemaId, databaseName) => {
    props.history.push('/search');
    props.setDbId(id);
    props.setSchemaId(schemaId);
    props.setDbName(databaseName);
  };

  const handleDatabaseList = () => {
    props.history.push('/Database');
  };

  const handleSchemaList = () => {
    props.history.push('/Schemas');
  };

  const handleSelectedSchema = schema => {
    props.history.push(`/schemaDetails/${schema.id}`);
  };

  const setDbName = (dbName, dbId) => {
    dispatch({ type: 'dbName', payload: dbName });
    dispatch({
      type: 'dbId',
      payload: dbId?.id,
    });
    props.history.push(`/search`);
  };

  const checkDatabaseListCount = () => {
    if (totalCountDatabase >= 8) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className="m-12"
          onClick={handleDatabaseList}
        >
          {`${EN.VIEW_ALL}`}
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const checkSchemaListCount = () => {
    if (totalCountSchema >= 8) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className="m-12"
          onClick={handleSchemaList}
        >
          {`${EN.VIEW_ALL}`}
        </Button>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Databases"
            value={
              metrics &&
              metrics.databases !== undefined &&
              metrics.databases !== null
                ? metrics.databases
                : '...'
            }
            icon={<StorageIcon />}
            color="#009688"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Objects"
            value={
              metrics &&
              metrics.objects !== undefined &&
              metrics.objects !== null
                ? metrics.objects
                : '...'
            }
            icon={<DescriptionIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Schemas"
            value={
              metrics &&
              metrics.schemas !== undefined &&
              metrics.schemas !== null
                ? metrics.schemas
                : '...'
            }
            icon={<AccountTreeIcon />}
            color="rgb(224 117 66)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            title="Annotation Projects"
            value={
              metrics &&
              metrics.projects !== undefined &&
              metrics.projects !== null
                ? metrics.projects
                : '...'
            }
            icon={<LocalOffer />}
            color="#3f51b5"
          />
        </Grid>
      </Grid>
      <hr className="m-15"></hr>
      <div className="m-11">
        <Grid item xs={12} sm={12} md={12} className="m-10">
          <label className={'mb-0 mt-10 m-13'}>Databases</label>
          {checkDatabaseListCount()}
        </Grid>
        <Grid container spacing={1}>
          {listOptions.map(db => (
            <Grid key={db.name} item xs={12} sm={6} md={3}>
              <Link
                style={{ textDecoration: 'none' }}
                //to={`/search?database=${db.name}`}
                onClick={() => setDbName(db.name, db.id)}
              >
                <ProductCardCompact
                  name={db.name}
                  thumbnail={db.thumbnail}
                  description={db.description}
                  uploadedTime={db && db.createdTime}
                  threedots={false}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="m-11">
        <Grid item xs={12} sm={12} md={12} className="m-10">
          <label className={'mb-0 mt-10 m-13'}>Schemas</label>
          {checkSchemaListCount()}
        </Grid>
        <Grid container spacing={1}>
          {renderschemasList()}
        </Grid>
      </div>
      <hr className="m-15"></hr>
      <Grid item xs={12} sm={12} md={12} className="m-10">
        <label className="mt-10 m-13 ">
          Latest notifications (Coming Soon)
        </label>
        {renderDropdown()}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Paper className="table-responsive">
          <Table className="relative-position">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {dataForNotification && dataForNotification.length ? (
              <>
                <TableBody>
                  {dataForNotification.map(n => (
                    <TableRow key={n.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ color: '#a5a5a5' }}
                      >
                        {n.date}
                      </TableCell>
                      <TableCell style={{ color: '#a5a5a5' }}>
                        {n.desc}
                      </TableCell>
                      <TableCell>
                        <a
                          href="#"
                          style={{ color: '#a5a5a5', textDecoration: 'none' }}
                        >
                          {n.action}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* <div className="notifications-table-overlay">
                  <div className="notifications-table-overlay-message">
                    Work in progress
                  </div>
                </div> */}
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                    <div className="no-latest-notifications-message">
                      No Notifications
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Paper>
      </Grid>
    </Wrapper>
  );
};
const mapStateToProps = state => {
  return {
    tempDatabaseList: state.database.databaseList.responseList,
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDatabaseList,
      getSchemasList,
      getUserDetails,
      getMetrics,
      labelStudioAuth,
      setDbId,
      setSchemaId,
      setDbName,
    },
    dispatch
  );

const ModuleForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);

export default withRouter(
  reduxForm({
    form: 'DatabaseForm',
    // enableReinitialize: true
  })(ModuleForm)
);
