/* eslint-disable */

import React, { useEffect, useState } from 'react';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { IconButton} from '@mui/material';


const PlayAudioButtonComponent = ({message, synth, voice}) => {

  const [paused, setPaused] = useState(false);

  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  }, []);


  const speakText = () => {
    //const synth = window.speechSynthesis;
    //setTimeout(() => {
    //  console.log(window.speechSynthesis.getVoices());
    //   const voices = synth.getVoices();
    // console.log(voices)
    // const britishVoice = voices.find(voice => voice.name === "Google UK English Male"
    // );
    const utterance = new SpeechSynthesisUtterance(message);
    utterance.voice = voice;
    // if (britishVoice) {
    // }

    // console.log(utterance.voice)
    // console.log(utterance.voice)
    synth.speak(utterance);
    //setPaused(false)
    let r = setInterval(() => {
      //console.log(speechSynthesis.speaking);
      if (!speechSynthesis.speaking) {
        clearInterval(r);
      } else {
        speechSynthesis.pause();
        console.log("Pause in else: ", paused)
        if(!paused) {
          console.log("Pause in if: ", paused)
          speechSynthesis.resume();
        }
      }
    }, 14000);
    //}, 100);
  };

  const onPause = () => {
    setPaused(true)
    speechSynthesis.pause();
    console.log(paused)
  }

  const onResume = () => {
    //setPaused(false)
    speechSynthesis.resume();
  }

  const onStop = () => {
    //setPaused(false)
    speechSynthesis.cancel();
   }

  const onRestart = () => {
    //setPaused(false)
    onStop();
    speakText();
  }

  const iconStyle = {
    color: "#3f51b5"
  };

  return (
    <span>
    {!paused && <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={speakText}>
      <PlayCircleFilled />
    </IconButton>}
    { paused && <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={onResume}>
      <PlayCircleFilled />
    </IconButton>}
    <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={onPause}>
      <PauseCircleFilled />
    </IconButton>
    <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={onStop}>
      <StopCircleIcon />
    </IconButton>
    <IconButton style={iconStyle} aria-label="play audio" size="small" onClick={onRestart}>
      <ReplayCircleFilledIcon />
    </IconButton>
    </span>
  );
};

export default PlayAudioButtonComponent;
